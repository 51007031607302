import React from "react"
import Layout from "../components/layout"
import { Helmet } from "react-helmet"

const PrivacyPage = () => (
  <Layout>
    <Helmet>
      <title>Managers App Privacy Policy</title>
      <meta name="description" content="ManagersApp Privacy Policy" />
    </Helmet>
    <div className={"container"}>
      <div>
        <h1
          style={{ fontSize: "24px", fontWeight: "bold", marginBottom: "20px" }}
        >
          Privacy Policy
        </h1>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Log Files
          </h2>
          <p>
            ManagersApp follows the standard procedure of using log files. These
            files log visitors when they visit our website. All hosting
            companies do this as part of their analytics. The information
            collected by log files includes internet protocol (IP) addresses,
            browser type, Internet Service Provider (ISP), date and time stamp,
            referring/exit pages, and possibly the number of clicks. These are
            not linked to any information that is personally identifiable. The
            purpose of the information is for analyzing trends, administering
            the site, tracking users' movement on the website, and gathering
            demographic information.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Cookies and Web Beacons
          </h2>
          <p>
            Like any other website, ManagersApp uses 'cookies'. These cookies
            are used to store information, including visitors' preferences, and
            the pages on the website that the visitor accessed or visited. This
            information is used to optimize the users' experience by customizing
            our web page content based on visitors' browser type and/or other
            information.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Privacy Policies
          </h2>
          <p>
            You may consult this list to find the Privacy Policy for each of the
            advertising partners of ManagersApp. Our Privacy Policy was created
            with the help of the Privacy Policy Generator.
          </p>
          <p>
            Third-party ad servers or ad networks use technologies like cookies,
            JavaScript, or Web Beacons in the advertisements and links that
            appear on ManagersApp. These technologies are sent directly to
            users' browsers and the ad server or network automatically receives
            your IP address when this occurs. These technologies are used to
            measure the effectiveness of their advertising campaigns and/or to
            personalize the advertising content that you see on websites that
            you visit.
          </p>
          <p>
            Note that ManagersApp has no access to or control over these
            cookies, which are used by third-party advertisers.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Third Party Privacy Policies
          </h2>
          <p>
            ManagersApp's Privacy Policy does not apply to other advertisers or
            websites. Thus, we are advising you to consult the respective
            Privacy Policies of these third-party ad servers for more detailed
            information. Details may include their practices and instructions
            about how to opt-out of certain options. You may find a complete
            list of these Privacy Policies and their links here: Privacy Policy
            Links.
          </p>
          <p>
            You can choose to disable cookies through your individual browser
            options. For more detailed information about cookie management with
            specific web browsers, I refer you to the browsers' respective
            websites.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Children's Information
          </h2>
          <p>
            Another part of our priority is adding protection for children while
            using the internet. We encourage parents and guardians to observe,
            participate in, and/or monitor and guide their online activity.
          </p>
          <p>
            ManagersApp does not knowingly collect any Personal Identifiable
            Information from children under the age of 13. If you think that
            your child provided this kind of information on our website, we
            strongly encourage you to contact us immediately and we will do our
            best to promptly remove such information from our records.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Online Privacy Policy Only
          </h2>
          <p>
            This Privacy Policy applies only to our online activities and is
            valid for visitors to our website with regards to the information
            that they shared and/or collected in ManagersApp. This policy is not
            applicable to any information collected offline or via channels
            other than this website.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Data Access, Deletion, and Transfer Requests
          </h2>
          <p>
            We understand the importance of transparency and control over your
            personal data. As a user of ManagersApp, you have the right to
            request access, deletion, or transfer of your individual data. We
            provide the following mechanisms for you to exercise these rights:
          </p>
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Access Requests:
          </h3>
          <p>
            If you would like to access the personal data we hold about you,
            please submit a request to support@managers.app. We will respond to
            your request within 3-5 business days and provide you with the
            requested information, subject to any legal or contractual
            limitations.
          </p>
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Deletion Requests:
          </h3>
          <p>
            To request the deletion of your personal data from our app, please
            email support@managers.app. Depending on the nature of your request,
            we may require additional verification to ensure the security and
            privacy of your data.
          </p>
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Data Transfer Requests:
          </h3>
          <p>
            If you wish to transfer your personal data to another service
            provider, please contact our support team or submit a request
            through support@managers.app. We will assist you in transferring
            your data in a commonly used electronic format, ensuring its
            security during the process.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Contact Information
          </h3>
          <p>
            For any questions, concerns, or to make a data access, deletion, or
            transfer request, please contact our support team at
            support@managers.app. We are dedicated to assisting you with your
            requests and ensuring the privacy and security of your data.
          </p>
          <p>
            Please note that while we make every effort to accommodate your data
            requests, there may be legal or legitimate reasons that prevent us
            from fulfilling certain requests. We will provide you with an
            explanation if we are unable to fulfill your specific request.
          </p>
          <p>
            We reserve the right to verify your identity before processing any
            data access, deletion, or transfer requests to ensure the security
            and privacy of your personal information.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h3
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Data Retention
          </h3>
          <p>
            We retain your personal data for as long as necessary to provide our
            services to you and as required by law. The specific duration for
            which we retain your data may vary depending on the purpose for
            which it was collected and the legal basis for processing it.
          </p>
          <p>
            If you have any questions about the retention period of your
            specific personal data or would like further information, please
            contact our support team at support@managers.app.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Data Collection
          </h2>
          <p>When you use our service, we collect the following data:</p>
          <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
            <li>
              name, email address, team member names and career-related
              information, billing information, or any other relevant data.
            </li>
          </ul>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "16px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Data Collection
          </h2>
          <p>ManagersApp collects the following data:</p>
          <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
            <li>
              Personal information that you provide us, such as your name, email
              address, slack workspace information.
            </li>
            <li>
              Usage data, such as the pages you visit on our website, the links
              you click, and the search terms you use.
            </li>
            <li>
              Technical data, such as your IP address, browser type, and
              operating system.
            </li>
          </ul>
          <p>We use this data to:</p>
          <ul style={{ listStyleType: "disc", marginLeft: "20px" }}>
            <li>Provide you with the services you request.</li>
            <li>Improve our website and services.</li>
            <li>Send you marketing communications.</li>
            <li>Protect our legal rights.</li>
          </ul>
          <p>
            We may share your data with third-party service providers who help
            us to provide our services. We will only share your data with third
            parties who have agreed to protect your privacy.
          </p>
          <p>
            You can opt out of receiving marketing communications from us at any
            time by clicking on the "unsubscribe" link at the bottom of any
            marketing email.
          </p>
          <p>
            You can also request that we delete your data by contacting us at
            support@managers.app. We will delete your data within a reasonable
            period of time.
          </p>
        </section>

        <section style={{ marginBottom: "20px" }}>
          <h2
            style={{
              fontSize: "20px",
              fontWeight: "bold",
              marginBottom: "10px",
            }}
          >
            Security
          </h2>
          <p>
            We take the security of your personal information seriously and use
            industry-standard security measures to protect it. However, no
            method of transmission over the internet or electronic storage is
            completely secure, so we cannot guarantee its absolute security.
          </p>
          <p>
            If you have any questions about the security of your personal
            information, please contact us at support@managers.app.
          </p>
        </section>
      </div>
    </div>
  </Layout>
)

export default PrivacyPage
